@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
/* amasty locator custom variables */
/* override locator styling only if amasty locator is active on its own page */
body.amlocator-index-index {
  /* add missing padding due to missing breadcrumb */
}

body.amlocator-index-index .page-main {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

/* make sure the styles are applied globally */
body {
  /* add font size overrides for container */
  /* add hover text decoration on street view link */
  /* search icon replace and styling */
  /* search input on focus styles override */
  /* range slider styles */
  /*store location spacing between title and details */
  /* remove bottom margin from store title */
  /* store locations selected styles */
  /* tooltip that appears within the maps */
  /* schedule dropdown styles */
  /* clock icon replace and style */
  /* pagination */
  /* dropdown filter */
  /* customer contact centre page */
}

body .amlocator-main-container {
  font-size: 14px;
}

body .amlocator-main-container .amlocator-search-container .amlocator-button.-nearby {
  width: auto;
  margin: 10px auto;
}

body .amlocator-column .amlocator-block .amlocator-link:hover {
  text-decoration: underline;
}

body .amlocator-current-location .amlocator-search {
  background: none;
  color: rgba(151, 153, 156, 0.5);
}

body .amlocator-current-location .amlocator-search:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .amlocator-current-location .amlocator-text:focus + .amlocator-search,
body .amlocator-current-location .amlocator-search:hover {
  background: none;
  color: #000;
}

body .amlocator-current-location .amlocator-text:focus {
  border: 1px solid #b0aa00;
  box-shadow: none;
}

body .amlocator-range-slider .amlocator-tooltip,
body .amlocator-range-slider .amlocator-tooltip:after {
  background-color: #b0aa00;
}

body .amlocator-range-slider .amlocator-handle {
  background-color: #b0aa00;
}

body .amlocator-range-slider .amlocator-handle {
  box-shadow: 0 2px 3px rgba(176, 170, 0, 0.5);
}

body .amlocator-range-slider {
  background-color: rgba(176, 170, 0, 0.1);
}

body .amlocator-info-popup .amlocator-image {
  display: block;
  clear: unset;
  width: unset;
  margin: 0;
  padding: 0;
}

body .amlocator-store-desc .amlocator-title {
  margin-bottom: 0;
}

body .amlocator-store-desc.-active {
  background-color: #000;
}

body .amlocator-store-desc.-active .amlocator-today {
  background-color: #D7D38A;
}

body .amlocator-info-popup {
  width: 100%;
  padding-right: 10px;
}

body .amlocator-info-popup .amlocator-name {
  margin: 0;
  font-size: 13px;
}

body .amlocator-schedule-container .amlocator-week {
  margin: 20px;
}

body .amlocator-schedule-container .amlocator-today:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .amlocator-schedule-container .amlocator-today:before {
  background: none;
  top: 5px;
}

body .amlocator-pager-container .pages .current .page,
body .amlocator-pager-container .pages .page:hover {
  background-color: #b0aa00;
}

body .amlocator-attribute-wrapper .amlocator-select,
body .amlocator-attribute-wrapper .chosen-choices {
  width: 100%;
}

body .amlocator-route-creator {
  background-color: #b0aa00;
}

body .amlocator-route-creator .amlocator-mode:checked + .amlocator-label,
body .amlocator-route-creator .amlocator-mode + .amlocator-label:hover {
  background-color: #000;
}

body .amlocator-route-icon .amlocator-icon.-dot {
  background-color: #000;
}

body .amlocator-route-creator .amlocator-mode + .amlocator-label {
  opacity: 0.7;
}

body .amlocator-route-creator .amlocator-address {
  width: 100%;
}

body .amlocator-location-info .amlocator-icon {
  background-color: #000;
}

body .amlocator-location-container .amlocator-schedule-container .amlocator-today:before {
  background: none;
}

body .amlocator-main-container .amlocator-current-location .amlocator-text {
  height: auto;
  font-weight: bold;
  border-color: #000;
  border-width: 2px;
}

body .amlocator-main-container .amlocator-current-location .amlocator-search {
  top: 13px;
  color: #000;
}

body .amlocator-store-desc .amlocator-title .amlocator-link {
  word-break: break-word;
  word-wrap: break-word;
}
