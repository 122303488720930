/* override locator styling only if amasty locator is active on its own page */
$am-locations-ccc-bg-color: map-get($theme-colors, 'primary');
$am-locations-ccc-icon-active-hover-color: map-get($theme-colors, 'dark');

body.amlocator-index-index {
    /* add missing padding due to missing breadcrumb */
    .page-main {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
}

/* make sure the styles are applied globally */
body {
    /* add font size overrides for container */
    .amlocator-main-container {
        font-size: 14px;

        .amlocator-search-container .amlocator-button.-nearby {
            width: auto;
            margin: 10px auto;
        }
    }

    /* add hover text decoration on street view link */
    .amlocator-column .amlocator-block .amlocator-link:hover {
        text-decoration: underline;
    }

    /* search icon replace and styling */
    .amlocator-current-location .amlocator-search {
        @include ms-icon('search', 20px);
        background: none;
        color: $am-locations-search-icon-color;
    }

    .amlocator-current-location .amlocator-text:focus + .amlocator-search,
    .amlocator-current-location .amlocator-search:hover {
        background: none;
        color: $am-locations-search-icon-color-hover;
    }

    /* search input on focus styles override */
    .amlocator-current-location .amlocator-text:focus {
        border: 1px solid $am-locations-border-color-focus;
        box-shadow: none;
    }

    /* range slider styles */
    .amlocator-range-slider .amlocator-tooltip,
    .amlocator-range-slider .amlocator-tooltip:after {
        background-color: $am-locations-tooltip-bg-color;
    }

    .amlocator-range-slider .amlocator-handle {
        background-color: $am-locations-slider-handle-bg;
    }

    .amlocator-range-slider .amlocator-handle {
        box-shadow: 0 2px 3px rgba($am-locations-slider-handle-bg, .5);
    }

    .amlocator-range-slider {
        background-color: rgba($am-locations-slider-handle-bg, .1);
    }

    /*store location spacing between title and details */
    .amlocator-info-popup .amlocator-image {
        display: block;
        clear: unset;
        width: unset;
        margin: 0;
        padding: 0;
    }

    /* remove bottom margin from store title */
    .amlocator-store-desc .amlocator-title {
        margin-bottom: 0;
    }

    /* store locations selected styles */
    .amlocator-store-desc.-active {
        background-color: $am-locations-store-desc-active;
    }
    .amlocator-store-desc.-active .amlocator-today {
        background-color: $am-locations-store-desc-active-open-times-bg;
    }

    /* tooltip that appears within the maps */
    .amlocator-info-popup {
        width: 100%;
        padding-right: 10px;
        .amlocator-name {
            margin: 0;
            font-size: 13px;
        }
    }

    /* schedule dropdown styles */
    .amlocator-schedule-container .amlocator-week {
        margin: 20px;
    }

    /* clock icon replace and style */
    .amlocator-schedule-container .amlocator-today {
        @include ms-icon('clock');

        &:before {
            background: none;
            top: 5px;
        }
    }

    /* pagination */
    .amlocator-pager-container .pages .current .page,
    .amlocator-pager-container .pages .page:hover {
        background-color: $am-locations-pagination-bg-hover-active;
    }

    /* dropdown filter */
    .amlocator-attribute-wrapper .amlocator-select,
    .amlocator-attribute-wrapper .chosen-choices {
        width: 100%;
    }

    /* customer contact centre page */
    .amlocator-route-creator {
        background-color: $am-locations-ccc-bg-color;
    }

    .amlocator-route-creator .amlocator-mode:checked + .amlocator-label,
    .amlocator-route-creator .amlocator-mode + .amlocator-label:hover {
        background-color: $am-locations-ccc-icon-active-hover-color;
    }

    .amlocator-route-icon .amlocator-icon.-dot {
        background-color: map-get($theme-colors, 'dark');
    }

    .amlocator-route-creator .amlocator-mode + .amlocator-label {
        opacity: 0.7;
    }

    .amlocator-route-creator .amlocator-address {
        width: 100%;
    }

    .amlocator-location-info .amlocator-icon {
        background-color: map-get($theme-colors, 'dark');
    }

    .amlocator-location-container .amlocator-schedule-container .amlocator-today:before {
        background: none;
    }
}


body {
    .amlocator-main-container .amlocator-current-location .amlocator-text {
        height: auto;
        font-weight: bold;
        border-color: $black;
        border-width: 2px;
    }

    .amlocator-main-container .amlocator-current-location .amlocator-search {
        top: 13px;
        color: $black;
    }
}
