//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

@import "modules/variables";
@import "modules/locator";


body .amlocator-store-desc .amlocator-title .amlocator-link {
    word-break: break-word;
    word-wrap: break-word;
}
